import { template as template_69cfd40a587f47a993c2ea176f75fab5 } from "@ember/template-compiler";
const FKControlMenuContainer = template_69cfd40a587f47a993c2ea176f75fab5(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
