import { template as template_1de6baeb158448beafeff1db5378b71b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_1de6baeb158448beafeff1db5378b71b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
