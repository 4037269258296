import { template as template_15109fd433f94cef85d110c926aac145 } from "@ember/template-compiler";
const FKLabel = template_15109fd433f94cef85d110c926aac145(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
